<template>
  <v-container class="mt-3 mx-3 px-0 white fill-height v-align-start font-inter card relative">
    <v-row justify="center">
      <v-col cols="12" sm="12">
        <v-progress-linear color="#0C2FF3" background-color="#F2F4F7" value="30"></v-progress-linear>
      </v-col>
      <v-col cols="12" sm="12" class="back-btn-container">
        <div class="back-btn">
          <router-link :to="`/tests/cases`">
            <v-icon>mdi-chevron-left</v-icon> {{ $t('backToTestCases') }}
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="block main-area">
        <h2>{{ $t('importFromCSV') }}</h2>
        <h5 class="mt-8">{{ $t('chooseFile') }}</h5>
        <v-file-input v-model="csvFile" draggable="true" class="round-8 file-input"
          dense height="136" full-width color="#0C111D" clearable prepend-inner-icon="" prepend-icon=""
          label="Drag&Drop or Browse files" style="background-color: #F9FAFB" @change="handleChange"/>
        <!-- <v-file-input class="round-8 file-input" multiple draggable="true" show-size="true"
        style="background-color: #F9FAFB !important;" prepend-inner-icon="" prepend-icon=""
        label="Drag&Drop or Browse files"
        dense height="136" full-width color="#0C111D">
        </v-file-input> -->
        <h5>{{ $t('importTo') }}</h5>
        <v-select dense height="38px" :items="importData" filled class="round-8" item-value="value" item-text="title" placeholder="Test placeholder"></v-select>
        <h5>{{ $t('template') }}</h5>
        <v-select dense height="38px" :items="templateData" filled class="round-8" item-value="value" item-text="title" placeholder="Test placeholder"></v-select>
        <h5>{{ $t('name') }}</h5>
        <v-text-field dense height="38px" filled class="round-8" placeholder="Name"></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" class="absolute bottom-0 flex justify-end">
        <v-btn class="text-capitalize text-white px-6 py-2 round-6 mr-6 mb-4" color="#0C2FF3" @click="goToMapping">{{ $t('goToColumnMapping') }}</v-btn>
        <!-- to="/tests/cases/import/csv/columns" -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data(){
    return{
      importData: [
        { title: "Android", value: 1 },
        { title: "Web", value: 2 },
        { title: "App", value: 3 },
      ],
      templateData: [
        { title: "Text", value: 1 },
        { title: "App", value: 2 },
      ],
      csvFile: null,
      contents: [],
    }
  },
  methods: {
    handleChange() {
      const reader = new FileReader();
      reader.readAsText(this.csvFile);
      reader.onload = () => {
        this.contents = reader.result;
      };
      reader.onerror = () => {
        console.log(reader.error);
      };
    },
    goToMapping() {
      let data = this.processData(this.contents);
      this.$router.push({
        name: 'ColumnMapping',
        params: { data: data }
      });
    },
    processData(allText) {
      const allTextLines = allText.split(/\r\n|\n/);
      const headers = allTextLines[0].split(',');
      let lines = [];

      for (let i=1; i<allTextLines.length; i++) {
        let data = allTextLines[i].split(',');
        if (data.length == headers.length) {
          let tarr = [];
          for (let j=0; j<headers.length; j++) {
              tarr.push(data[j]);
          }
          lines.push(tarr);
        }
      }
      const result = {
        headers: headers,
        data: lines
      }
      return result;
    },
  },
}
</script>
<style>
h2, h5{
  text-align: left;
}
h2{
  font-size: 24px !important;
  font-weight: 700;
  color: #18181A;
}
h5{
  font-size: 14px !important;
  color: #0C111D;
  font-weight: 500;
}
button {
  font-size: 14px;
}
.v-align-start {
  align-items: flex-start !important;
}
.font-inter{
  font-family: Inter;
}
.card{
  border-radius: 8px;
}
.block{
  display: block;
}
.main-area{
  max-width: 438px;
  margin-left: auto;
  margin-right: auto;
}
.round-8{
  border-radius: 8px;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.bottom-0{
  bottom: 0;
}
.justify-end{
  justify-content: flex-end;
}
.text-white{
  color: white !important;
}
.round-6{
  border-radius: 6px;
}
.flex{
  display: flex;
}
.file-input{
  border:1px solid #D0D5DD;
  border-style:dashed;
}
</style>
